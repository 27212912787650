import {useEffect, useState} from 'react';
import {Configuration, OrdersApi} from '@/api';
import Address from '@/components/Address';
import Button from '@/components/Button';
import Confirm from '@/components/Confirm';
import CustomToast from '@/components/CustomToast';
import DeliveryWindowSelectBox, {DeliveryWindowItem} from '@/components/DeliveryWindowSelectBox';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import OrderItemEditTable from '@/components/OrderItemEditTable';
import OrderItemTable from '@/components/OrderItemTable';
import OrderStatus from '@/components/OrderStatus';
import PageHeader from '@/components/PageHeader';
import Prompt from '@/components/Prompt';
import {DeliveryWindow, DeliveryWindowImpl} from '@/models/DeliveryWindow';
import {OrderAssociation} from '@/models/Order';
import {OrderStatus as OrderStatusModel} from '@/models/OrderStatus';
import {isSupplier} from '@/models/User';
import {useAuth} from '@/modules/auth/contexts/AuthContext';
import ReorderButton from '@/modules/retailer/components/ReorderButton';
import {CartPath, OrderListPath} from '@/modules/retailer/paths';
import {formatTimeWindow} from '@/utils/formatTimeWindow';
import {ArrowLeftIcon, DocumentArrowDownIcon, PencilIcon, XMarkIcon} from '@heroicons/react/24/outline';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import toast from 'react-hot-toast';
import {useNavigate, useParams} from 'react-router-dom';

interface OrderItemQuantities {
    [orderItemId: string]: number;
}

interface State {
    isEditing: boolean;
    confirmedQuantities?: OrderItemQuantities;
    deliveryWindow?: DeliveryWindow;
}

const OrderDetails = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const [state, setState] = useState<State>({
        isEditing: false,
        confirmedQuantities: undefined,
        deliveryWindow: undefined,
    });

    const {role} = useAuth();
    const {orderId} = useParams();

    const ordersApi = new OrdersApi(new Configuration(useAuth));

    const isSupplierRole = role && isSupplier(role);

    const {
        isLoading,
        data: order,
    } = useQuery({
        queryKey: ['order', orderId],
        queryFn: async () => {
            return orderId
                ? await ordersApi.getOrder(orderId, [
                    OrderAssociation.SUPPLIER,
                    OrderAssociation.STORE,
                    OrderAssociation.WAREHOUSE,
                ]) || null
                : null;
        },
    });

    const invalidateQueries = async () => {
        return queryClient.invalidateQueries({queryKey: ['order', orderId]});
    };

    useEffect(() => {
        setState(prevState => {
            if (prevState.confirmedQuantities !== undefined || order?.items === undefined) {
                return prevState;
            }
            return {
                ...prevState,
                confirmedQuantities: order?.items.reduce((quantities, item) => {
                    quantities[item.id] = item.quantity;
                    return quantities;
                }, {} as OrderItemQuantities) || {},
            };
        });
    }, [order?.items, state.confirmedQuantities]);

    if (isLoading) {
        return (
            <div className="flex justify-center py-20">
                <SpinnerIcon/>
            </div>
        );
    }

    if (!order) {
        return (
            <div className="flex justify-center py-20">
                Something went wrong ...
            </div>
        );
    }

    const deliveryWindow = state.deliveryWindow
        ? DeliveryWindowImpl.fromObject(state.deliveryWindow)
        : order.deliveryWindow
            ? DeliveryWindowImpl.fromObject(order.deliveryWindow)
            : undefined;

    const handleEditClick = () => {
        setState(prevState => ({
            ...prevState,
            isEditing: true,
        }));
    };

    const handleDiscardChangesClick = () => {
        setState(prevState => ({
            ...prevState,
            isEditing: false,
        }));

        void invalidateQueries();
    };

    const handleConfirmedQuantityChange = (quantity: number, orderItemId: string) => {
        setState(prevState => ({
            ...prevState,
            confirmedQuantities: {
                ...prevState.confirmedQuantities,
                [orderItemId]: quantity,
            },
        }));
    };

    const {
        isEditing,
        confirmedQuantities,
    } = state;

    const handleDeliveryWindowChange = (item: DeliveryWindowItem | null) => {
        setState(prevState => ({
            ...prevState,
            deliveryWindow: item?.deliveryWindow,
        }));
    };

    const handleUpdateAndAcceptOrder = async (): Promise<void> => {
        if (!confirmedQuantities || !state.deliveryWindow) {
            return;
        }

        try {
            await ordersApi.updateOrder(
                order.id,
                {
                    confirmedDeliveryWindow: state.deliveryWindow,
                    items: order.items.map(item => ({
                        ...item,
                        confirmedQuantity: confirmedQuantities[item.id],
                    })),
                },
            );

            await ordersApi.acceptOrder(order.id);

            toast.custom((t) => (
                <CustomToast
                    id={t.id}
                    message={`Successfully updated and accepted order #${order.code}`}
                    visible={t.visible}
                />
            ));

            void invalidateQueries();

            setState(prevState => ({
                ...prevState,
                isEditing: false,
            }));
        } catch (error) {
            console.error('Error while accepting order', error);
        }
    };

    const handleAcceptOrder = async (): Promise<void> => {
        try {
            await ordersApi.acceptOrder(order.id);

            toast.custom((t) => (
                <CustomToast
                    id={t.id}
                    message={`Successfully accepted order #${order.code}`}
                    visible={t.visible}
                />
            ));
            void invalidateQueries();
        } catch (error) {
            console.error('Error while accepting order', error);
        }
    };

    const handleCompleteOrder = async (): Promise<void> => {
        try {
            await ordersApi.completeOrder(order.id);

            toast.custom((t) => (
                <CustomToast
                    id={t.id}
                    message={`Successfully completed order #${order.code}`}
                    visible={t.visible}
                />
            ));
            void invalidateQueries();
        } catch (error) {
            console.error('Error while completing order', error);
        }
    };

    const handleRejectOrder = async (reason: string): Promise<void> => {
        try {
            await ordersApi.rejectOrder(order.id, reason);
            toast.custom((t) => (
                <CustomToast
                    id={t.id}
                    message={`Successfully rejected order #${order.id}`}
                    visible={t.visible}
                />
            ));
            void invalidateQueries();
        } catch (error) {
            console.error('Error while rejecting order', error);
        }
    };

    const nav = isSupplierRole ? (
        <>
            <Button
                to={OrderListPath}
                icon={<ArrowLeftIcon className="w-5 h-5 mr-1"/>}
                variant="secondary"
            >
                Back
            </Button>
            {isEditing ? (
                <>
                    <Button
                        onClick={handleDiscardChangesClick}
                        icon={<XMarkIcon className="w-5 h-5 mr-1"/>}
                        variant="secondary"
                    >
                        Discard Changes
                    </Button>
                    <Confirm
                        onConfirm={handleUpdateAndAcceptOrder}
                        title="Accept Order"
                        message={`Are you sure you want to update and accept order #${order.code}?`}
                    >
                        {(handleClick, loading) => (
                            <Button
                                onClick={handleClick}
                                variant="primary"
                                loading={loading}
                            >
                                Update and Accept Order
                            </Button>
                        )}
                    </Confirm>
                </>
            ) : order.status === OrderStatusModel.Completed ? (
                <>
                    <Button
                        to={OrderListPath}
                        icon={<DocumentArrowDownIcon className="w-5 h-5 mr-1"/>}
                        variant="secondary"
                    >
                        Export
                    </Button>
                </>
            ) : order.status === OrderStatusModel.Accepted ? (
                <>
                    <Button
                        to={OrderListPath}
                        icon={<DocumentArrowDownIcon className="w-5 h-5 mr-1"/>}
                        variant="secondary"
                    >
                        Export
                    </Button>
                    <Confirm
                        onConfirm={handleCompleteOrder}
                        title="Complete Order"
                        message={`Are you sure you want to complete order #${order.code}?`}
                    >
                        {(handleClick, loading) => (
                            <Button onClick={handleClick} variant="primary" loading={loading}>
                                Complete Order
                            </Button>
                        )}
                    </Confirm>
                </>
            ) : order.status !== OrderStatusModel.Rejected ? (
                <>
                    <Button
                        to={OrderListPath}
                        icon={<DocumentArrowDownIcon className="w-5 h-5 mr-1"/>}
                        variant="secondary"
                    >
                        Export
                    </Button>
                    <Prompt
                        onConfirm={handleRejectOrder}
                        title="Reject Order"
                        message={`Are you sure you want to reject order #${order.code}?`}
                    >
                        {(handleClick, loading) => (
                            <Button
                                onClick={handleClick}
                                icon={<XMarkIcon className="w-5 h-5 mr-1"/>}
                                variant="secondary"
                                loading={loading}
                            >
                                Reject Order
                            </Button>
                        )}
                    </Prompt>
                    <Button
                        onClick={handleEditClick}
                        icon={<PencilIcon className="w-5 h-5 mr-1"/>}
                        variant="secondary"
                    >
                        Edit Order
                    </Button>
                    <Confirm
                        onConfirm={handleAcceptOrder}
                        title="Accept Order"
                        message={`Are you sure you want to accept order #${order.code}?`}
                    >
                        {(handleClick, loading) => (
                            <Button onClick={handleClick} variant="primary" loading={loading}>
                                Accept Order
                            </Button>
                        )}
                    </Confirm>
                </>
            ) : <></>}
        </>
    ) : (
        <>
            <ReorderButton
                order={order}
                onSuccess={() => navigate(CartPath)}
            />
            <Button to={OrderListPath} variant="secondary">
                Back
            </Button>
            <Button to={OrderListPath} variant="secondary">
                Export
            </Button>
        </>
    );

    return (
        <>
            <PageHeader title={`Order #${order.code}`}>
                {nav}
            </PageHeader>

            <div className="flex flex-col sm:flex-row w-full">
                <div className="flex-1">
                    <span className="block font-bold mb-1">Delivery Address:</span>

                    <p className="block mb-4">
                        <Address address={order.deliveryAddress}/>
                    </p>
                </div>

                <div className="flex-1">
                    {!isSupplierRole && <>
                        <span className="block font-bold mb-1">Supplier:</span>
                        <p className="block mb-4">{order.supplier ? order.supplier.name : ''}</p>
                    </>}

                    <span className="block font-bold mb-1">Expected Delivery Date:</span>
                    <p className="block mb-4">{order.deliveryWindow && formatTimeWindow(order.deliveryWindow)}</p>

                    <span className="block font-bold mb-1">Actual Delivery Date:</span>
                    <p className="block mb-4">{order.actualDeliveryDate ? formatTimeWindow(order.actualDeliveryDate) : 'Not Tracked'}</p>
                </div>

                <div className="flex-1">
                    <span className="block font-bold mb-4">Order Status:</span>

                    <OrderStatus order={order}/>
                </div>
            </div>

            <h2 className="block font-bold mb-1 text-lg">Order Items</h2>

            {isEditing && <div className="mb-4 lg:w-96">
                <DeliveryWindowSelectBox
                    value={deliveryWindow?.id || null}
                    onSelect={(item) => handleDeliveryWindowChange(item)}
                    supplierId={order.supplierId}
                    storeId={order.storeId}
                    defaultValue="first"
                />
            </div>}
            <div>
                {isEditing
                    ? <OrderItemEditTable
                        order={order}
                        confirmedQuantities={confirmedQuantities}
                        onChangeConfirmedQuantity={handleConfirmedQuantityChange}
                    />
                    : <OrderItemTable order={order}/>
                }
            </div>

            <div className="flex flex-col gap-2 mt-12 sm:flex-row sm:items-center sm:justify-end">
                {nav}
            </div>
        </>
    );
};

export default OrderDetails;
